import React from "react"
import type { HeadFC } from "gatsby"
import Layout from "../components/Layout"
import BrandBar from "../components/BrandBar";
import PlotTestimony from "../components/PlotTestimony";
import ProjectLifecycle from "../components/ProjectLifecycle";
import Google from "../components/Google";
import TopContentBar from "../components/TopContentBar";
import PopularPackages from "../components/PopularPackages/PopularPackages";
import { SEO } from "../components/SEO";

const IndexPage = () => {
  return (
    <>
      <Google />
      <Layout>
        <TopContentBar />
        <PopularPackages />
        <PlotTestimony />
        <BrandBar />
        <ProjectLifecycle />
      </Layout>
    </>
  )
}

export default IndexPage

export const Head: HeadFC = () => <SEO 
  title="Bar and Restaurant Sound System Installation" 
  description="We can create the perfect atmosphere. Working with your budget, we will design a system that is easy to control and covers the whole space."
  path="/bar-and-restaurant"
/>;
