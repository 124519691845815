import React, {FC} from "react"
import styled from "@emotion/styled";
import plot22 from "../images/plot-airten.webp"

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  margin-bottom: 50px;
  background-color: black;
  height: 500px;
  color: white;
`;

const InfoContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  * {
    margin-bottom: 20px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1400px;
  background: right;
  background-image: url(${plot22});
  background-repeat: no-repeat;
`; 

const TopContentBar: FC = () => {
  return <Container>
    <ContentContainer>
      <InfoContainer>
        <div>
          <h1>Bar and Restaurant Installation</h1>
          <p>At <b>Audio Engineering and Installation</b> we can create the perfect atmosphere.</p>
          <p>Working with your budget, we will design a system that is easy to control and covers the whole space.</p>
        </div>
      </InfoContainer>
    </ContentContainer>
  </Container>
}

export default TopContentBar;