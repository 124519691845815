import { useCallback, useLayoutEffect, useState } from "react";
import hasMounted from "./hasMounted";

const isMobile = (width: number = 500): boolean => {

  const mounted = hasMounted();

  const [screenWidth, setScreenWidth] = useState<number>(mounted ? window.innerWidth : 0);

  const processIsMobile = useCallback(() => {
    setScreenWidth(window.innerWidth);
  }, [setScreenWidth])

  useLayoutEffect(() => {
    window.addEventListener("resize", processIsMobile);

    processIsMobile();

    return () => {
      removeEventListener("resize", processIsMobile)
    }
  }, [processIsMobile, mounted]);

  return screenWidth < width;
}

export default isMobile;