import React, {FC} from "react"
import styled from "@emotion/styled";
import isMobile from "../../hooks/isMobile";

const Container = styled.div<{vertical: boolean}>(`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  background-color: black;
  color: white;
  background-size: contain;

`, ({vertical}) => {
  if(!vertical) {
    return {
      // backgroundImage: `url(${plotImage})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center;"
    }
  }

  return {};
});

const ContentContainer = styled.div<{vertical: boolean}>(`
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 25px 0;
  display: flex;
  justify-content: space-around;

  * {
    margin-bottom: 20px;
  }

  li {
    margin-bottom: 10px;
  }
`, ({vertical}) => {
  return {
    flexDirection: vertical?"column":"row"
  };
});

const DescriptionContainer = styled.div<{vertical: boolean}>(`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 12px;
  margin: 0 auto;

`, ({vertical}) => {
  if(vertical) {
    return {
      // backgroundImage: `url(${plotImageLeft})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "left;",
      backgroundSize: "contain",
      width: "100%",
      maxWidth: "100%"
    }
  }

  return {};
});

const TestimonialContainer = styled.div<{vertical: boolean}>(`
padding: 25px;
max-width: 500px;
margin: 0 auto;

`, ({vertical}) => {
  if(vertical) {
    return {
      // backgroundImage: `url(${plotImageRight})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "right;",
      backgroundSize: "contain",
      width: "100%",
      maxWidth: "100%"
    }
  }

  return {};
});

const Title = styled.h3`
  font-size: 52px;
`;

const Subtitle = styled.h3`
  font-size: 32px;
`;

const Image = styled.div`
  width: 100%;
  height: 40px;
  background-color: white;
`;


const Standard: FC = () => {

  const vertical = isMobile(860);

  return <Container vertical={vertical}>
    <ContentContainer vertical={vertical}>
      <DescriptionContainer vertical={vertical}>
        <Title>Standard</Title>
        <Subtitle>Contact Us for a Quote!</Subtitle>
        <ul>
          <li>Background Music</li>
          <li>Multiple Audio Sources</li>
          <li>Source from TV</li>
          <li>50hz to 20khz - 115db max SPL</li>
        </ul>
      </DescriptionContainer>
      <TestimonialContainer vertical={vertical}>
        <h4>Includes:</h4>
        <ul>
          <li><b>4</b> - Satellite Speakers for even coverage</li>
          <li><b>2</b> - Subwoofers for extended low frequencies</li>
          <li>Wall Mount Controller - Source &amp; Volume</li>
          <li>Full Amplification</li>
          <li>Effective Installation</li>
        </ul>
      </TestimonialContainer>
    </ContentContainer>
  </Container>
}

export default Standard;