import { useEffect, useState } from "react";

const hasMounted = (): boolean => {

  const [mounted, setMounted] = useState(false);
  
  useEffect(() => {
    setMounted(true);
  }, []);

  return mounted;
}

export default hasMounted;