import React, {FC} from "react"
import styled from "@emotion/styled";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  margin-bottom: 50px;
`;

const ContentContainer = styled.div`
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 12px;
`;

const Paragraph = styled.p`
  margin-bottom: 12px;
`;

const PlotTestimony: FC = () => {
  return <Container>
    <ContentContainer>
      <h2>Our Previous Work</h2>
      <Paragraph><b>Audio Engineering and Installation</b> have been a pleasure to work with! Taking on board our requirements as a small venue with a history of noise complaints, they were able to provide us with a system bespoke to our needs, still providing the right amount of volume in the space while ensuring levels are not too loud for patrons both inside and outside of the venue. </Paragraph>
      <Paragraph>The attention to detail and commitment to quality meant that they were able to further improve the sound quality of the installed system over time by making regular tweaks to fit our needs as they change. This has included tuning the system to get rid of unwanted vibrations and rattles inside the venue as well as improving the setup with upgrades as necessary.</Paragraph>
      <Paragraph>I would definitely recommend working with <b>Audio Engineering and Installation</b>! </Paragraph>
      <Paragraph><b>Mike</b> Owner of Plot 22</Paragraph>
    </ContentContainer>
  </Container>
}

export default PlotTestimony;