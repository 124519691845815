import React, {FC} from "react"
import styled from "@emotion/styled";
import Standard from "./Standard";
import Premium from "./Premium";
import Custom from "./Custom";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  margin-bottom: 50px;
`;

const ContentContainer = styled.div`
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  margin-bottom: 50px;
  text-align: center;
`;

const Title = styled.h2`
  font-size: 84px;

  @media screen and (max-width: 460px) {
    font-size: 53px;
  }

`;

const PopularPackages: FC = () => {

  return <Container>
    <ContentContainer>
      <Title>Popular Packages</Title>
      <p>Setups chosen by most Bars or Restaurants</p>
    </ContentContainer>
    <Standard />
    <Premium />
    <Custom />
  </Container>
}

export default PopularPackages;